import * as React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout';
import PageContent from '../components/PageContent';
import Gallery from '@browniebroke/gatsby-image-gallery'

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  .back {
    font-size: 12px;
  }
  h3 {
    margin: 0 0 2px 0; 
    font-size: 22px;
    height: 26px;
  }
  h5 {
    margin: 0 0 0 0;
  }
`;

export function GalleryPage(props) {
  const { title, eventType } = props.data.markdownRemark.frontmatter;
  const images = props.data.allFile.edges.map(edge => edge.node.childImageSharp);

  return (
    <Layout subPageTitle={`${title} ${eventType}`}>
      <PageContent>
          <Header>
              <div>
                <h3>{title}</h3>
                <h5>{eventType}</h5>
              </div>
              <Link className='back' to='/gallery'>⬅ to Gallery</Link>
          </Header>
          <Gallery
            images={images}
            lightboxOptions={{
              // when fullscreened
              imagePadding: 0,
              // low quality kind of... i'd skip for now
              enableZoom: false,
            }}
          />
      </PageContent>
      </Layout>
  );
}

// relativeDirectory and id come from the context defined in gatsby-node.js at the root
export const query = graphql`
  query($id: String, $relativeDirectory: String) {
      allFile(
        sort: { fields: base },
        filter: {
          sourceInstanceName: {eq: "gallery-pages"},
          relativeDirectory: {eq: $relativeDirectory}
          ext: {regex: "/.(JPG|jpg|png)/"}
        }
      ) {
        edges {
          node {
            id
            childImageSharp { 
              thumb: fluid(maxWidth: 500, maxHeight: 500) {
                ...GatsbyImageSharpFluid
              }
              full: fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        eventType
        eventDate
      }
    }
  }
`;

export default GalleryPage;
